<!-- 小程序和生成个案查询用户组件 -->
<template>
  <div>
    <!-- 查找条件 -->
    <el-form :inline="true" :model="formInline" class="searchKeyWord-input" >
    <!--名称 -->
  <el-form-item :label="$t('component.newrecords.label.name')">
    <el-input v-model="formInline.name" placeholder=""></el-input>
  </el-form-item>
  <!--简档 -->
  <el-form-item :label="$t('label.profile')">
    <el-input v-model="formInline.profileName" placeholder=""></el-input>
  </el-form-item>
 
</el-form>
    <!-- 按钮 -->
    <div style="margin-bottom: 10px">
      <el-button size="mini" type="primary" @click="search">
        <!-- 搜索 -->
        {{ $t("label.searchs") }}
      </el-button>
    </div>
    <!-- 列表 -->
    <table-panel
      class="text-center"
      ref="tablePanel"
      :border="true"
      :show-lock="false"
      :table-height="tableHeight"
      :show-index="false"
      :pict-loading="pictLoading"
      :table-attr="tableAttr"
      :page-obj="pageObj"
      :noDataTip="noDataTip"
      :use-hyperlinks="false"
      @append="append"
      @changeDate="changeDate"
    >
    </table-panel>
  </div>
</template>

<script>
import TablePanel  from "./tablePanel.vue";
import * as CommonObjApi from "./api";

export default {
  name: "SearchTable",
  components: { TablePanel },
  props: {
    userstr: {
      type: Array,
      default: ()=> [],
    },
    bindtype: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
        userstrProp: this.userstr,
        // 条件搜索
         formInline: {
          name: '', //用户名
          profileName: '' // 简档
        },
      tableHeight: 400,
      // objId: this.$route.params.objId,
      tableAttr: null, // 表格列数据
      pageObj: {
        dataList: [],
      }, // 表格数据
      pictLoading: false,
      noDataTip: false, // 是否是全部数据，
      page: 1, //页码默认为1
      pageSize: 20, // 每页展示数据条数
    };
  },
  created() {
    var _this = this;
    document.onkeydown = function () {
      //按下回车提交
      let key = window.event.keyCode;
      //事件中keycode=13为回车事件
      if (key == 13) {
        _this.search();
      }
    };
  },
  methods: {
    
    init() {
      this.page = 1;
      this.formInline= {
          name: '', //用户名
          profileName: '' // 简档
        }
      this.pageObj.dataList = [];
      this.getLookupData()
      
    },
    // 搜索
    search() {
      this.page = 1;
      this.pageObj.dataList = [];
      this.getLookupData();
    },
    // 滚动加载数据
    append() {
      if (!this.noDataTip) {
        this.getLookupData();
      }
    },
    // 获取查找数据
    
    getLookupData() {
      if (this.page === 1) {
        this.tableAttr = null;
      }
      this.formInline.page = this.page;
      this.pictLoading = true;
      this.formInline.userBind = this.userstrProp.length > 0 ? this.userstrProp.join(",") : "1"
      this.formInline.bindType = this.bindtype;
      CommonObjApi.getUserList(this.formInline).then((res) => {
        if (res.result) {
          // 页码为1时重新请求表头
          if (this.page === 1) {
            // 自定义列表展示顺序
            this.tableAttr=new Array(7).fill(null)
            res.data.headLabel.forEach(item=>{
              switch (item.apiname) {
                case 'userlicence':
                  this.tableAttr[4]=item;break
                case 'loginname':
                  this.tableAttr[0]=item;break
                  case 'profile':
                  this.tableAttr[3]=item;break
                  case 'email':
                  this.tableAttr[5]=item;break
                  case 'id':
                  this.tableAttr[1]=item;break
                   case 'mobile':
                  this.tableAttr[6]=item;break
                   case 'name':
                  this.tableAttr[2]=item;break
              }
            })
            // this.tableAttr = res.data.headLabel;
            this.tableAttr.forEach((item) => {
              // 名称、查找、主详、查找多选均可跳转
              if (
               item.schemefieldName === "name" ||
                item.schemefieldType === "Y" ||
                item.schemefieldType === "M" ||
                item.schemefieldType === "MR"
              ) {
                 item.click = "selectData";
              }
            });
            
            this.$refs.tablePanel.changeTableAttr(this.tableAttr);
          }

          this.totalSize = res.data.totalCount;
          if (res.data.dataList.length === 0 && this.page === 1) {
            this.pageObj.dataList = [];
          } else if (this.pageObj.dataList.length === 0 || this.page === 1) {
            if (res.data.dataList.length > this.pageSize) {
              this.pageObj.dataList = res.data.dataList.slice(
                0,
                res.data.dataList.length - 1
              );
            } else {
              this.pageObj.dataList = res.data.dataList;
            }
            this.page++;
          } else {
            // 记忆选中数据
            let checkedList = this.$refs.tablePanel.getCheckedList();
            if (res.data.dataList.length > this.pageSize) {
              this.pageObj.dataList = [
                ...this.pageObj.dataList,
                ...res.data.dataList.slice(0, res.data.dataList.length - 1),
              ];
            } else {
              this.pageObj.dataList = [
                ...this.pageObj.dataList,
                ...res.data.dataList,
              ];
            }
            this.pageObj.dataList.forEach((element) => {
              checkedList.forEach((item) => {
                if (item.id === element.id) {
                  element.checked = true;
                }
              });
            });
            this.page++;
            this.$refs.tablePanel.dataList = this.pageObj.dataList;
          }
          // 判断是否有更多数据
          if (Number(res.data.dataList.length) === this.pageSize) {
            this.noDataTip = false;
          } else {
            this.noDataTip = true;
          }
          this.pictLoading = false;
          this.pageObj.totalSize = res.data.totalCount;
        } else {
          this.pictLoading = false;
          this.tableAttr = [];
          this.pageObj.totalSize = 0;
          this.pageObj.dataList = [];
          this.$message.error(res.stackTrace);
        }
      });
    },
    // 改变选中的表格值
    changeDate(row){
      this.$emit('changeSelect',row)
    }
  }
};
</script>

<style lang="scss" scoped>
.searchKeyWord-input {
  margin-bottom: 10px;
}

::v-deep .el-input__inner {
  height: 34px;
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}
</style>
