/* eslint-disable vue/no-v-for-template-key */
<template>
  <div id="table-panel" style="width: 100%">
    <!-- 表格组件 -->
    <div class="content-main">
      <!-- 骨架屏 -->
      <Wang-skeleton
        type="line"
        :options="skeletonOptions"
        v-if="pageNum === 1"
      >
      </Wang-skeleton>

      <el-table
        ref="tablePanel"
        id="table"
        :data="dataList"
        v-loadmore="loadMore"
        v-if="pageNum !== 1"
        :border="border"
        :height="tableHeight"
        aria-activedescendant=""
        v-loading="pictLoading"
        @row-click="rowClick"
        @toggleSelection="clearSelection"
        :empty-text="$t('label.dashboard.noRecord')"
        :stripe="stripe"
        :style="{ width: '100%', 'min-height': minHeight ? '418px' : '0' }"
      >
        <!-- 表头&内容展示区 -->
        <template v-for="(item, itemIndex) in tableAttr">
          <slot :name="item.schemefieldName" :item="item">
            <!-- html展示数据，一般用于富文本框 -->
            <el-table-column
              v-if="
                !item.click && !item.options && !item.isInput && item.ishtml
              "
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="true"
            >
              <!-- 自定义表头部分 -->
              <template slot="header" v-if="customHeader">
                <!-- 自定义表头信息 -->
                <div class="tableHeadField">
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    style="display: inline-block; width: 10px; height: 12px"
                    aria-hidden="true"
                    v-show="item.sortByThis"
                  >
                    <use
                      :href="item.sortDir === 'desc' ? topImg2 : topImg1"
                    ></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">
                      {{ $t("vue_label_commonobjects_detail_locking") }}
                    </el-checkbox>
                    <span slot="reference">
                      <svg
                        style="display: inline-block; width: 11px; height: 11px"
                        aria-hidden="true"
                      >
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <div v-html="scope.row[item.schemefieldName]"></div>
              </template>
            </el-table-column>
            <!-- 普通文本类型数据 -->
            <el-table-column
              v-if="
                !item.click && !item.options && !item.isInput && !item.ishtml
              "
              :align="item.align ? item.align : 'left'"
              :width="item.colwidth"
              :label="item.nameLabel"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="true && item.schemefieldType !== 'B'"
            >
              <template slot="header" v-if="customHeader">
                <div class="tableHeadField">
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span> </el-popover
                  ><svg
                    style="display: inline-block; width: 10px; height: 12px"
                    aria-hidden="true"
                    v-show="item.sortByThis"
                  >
                    <use
                      :href="item.sortDir === 'desc' ? topImg2 : topImg1"
                    ></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <span slot="reference">
                      <svg
                        style="display: inline-block; width: 11px; height: 11px"
                        aria-hidden="true"
                      >
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <!--  单选下拉框 -->
            <el-table-column
              v-if="item.options && !item.isInput"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="item.tooltip"
            >
              <template slot="header" v-if="customHeader">
                <div class="tableHeadField">
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    style="display: inline-block; width: 10px; height: 12px"
                    aria-hidden="true"
                    v-show="item.sortByThis"
                  >
                    <use
                      :href="item.sortDir === 'desc' ? topImg2 : topImg1"
                    ></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <span slot="reference">
                      <svg
                        style="display: inline-block; width: 11px; height: 11px"
                        aria-hidden="true"
                      >
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <!-- a标签可跳转类型字段 - 查找、查找多选、所有人 -->
            <el-table-column
              v-if="item.click && !item.isInput && useHyperlinks"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="columnTooltip"
            >
              <template slot="header" v-if="customHeader">
                <div class="tableHeadField">
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    style="display: inline-block; width: 10px; height: 12px"
                    aria-hidden="true"
                    v-show="item.sortByThis"
                  >
                    <use
                      :href="item.sortDir === 'desc' ? topImg2 : topImg1"
                    ></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <span slot="reference">
                      <svg
                        style="display: inline-block; width: 11px; height: 11px"
                        aria-hidden="true"
                      >
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <!-- 不使用a标签 - 查找、查找多选、所有人 -->
            <el-table-column
              v-if="item.click && !item.isInput && !useHyperlinks"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="columnTooltip"
            >
              <template slot-scope="scope">
                <!-- 查找字段 -->
                <span
                  class="cursor-pointer allow-click"
                  v-if="item.schemefieldType !== 'MR'"
                >
                  <!-- 名称和相关项 -->
                  <span
                    v-if="
                      item.schemefieldName === 'relateid' ||
                      item.schemefieldName === 'whoid'
                    "
                  >
                    {{
                      item.schemefieldName === "relateid" ||
                      item.schemefieldName === "whoid"
                        ? scope.row[`${item.schemefieldName}ccname`]
                        : scope.row[`${item.schemefieldName}`]
                    }}
                  </span>
                  <span v-else>
                    {{
                      item.schemefieldType === "Y" ||
                      item.schemefieldType === "MR" ||
                      item.schemefieldType === "M"
                        ? scope.row[`${item.schemefieldName}ccname`]
                        : scope.row[`${item.schemefieldName}`]
                    }}
                  </span>
                </span>
                <span v-else>
                  {{
                    item.schemefieldType === "Y" ||
                    item.schemefieldType === "MR" ||
                    item.schemefieldType === "M" ||
                    item.schemefieldName === "relateid" ||
                    item.schemefieldName === "whoid"
                      ? scope.row[`${item.schemefieldName}ccname`]
                      : scope.row[`${item.schemefieldName}`]
                  }}
                </span>
              </template>
            </el-table-column>
            <!-- 数值类型 -->
            <el-table-column
              v-if="item.isInput"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="columnTooltip"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row[item.schemefieldName]"
                  v-if="item.inputAttr.type === 'number'"
                  size="mini"
                  :min="item.inputAttr ? item.inputAttr.min : 0"
                  :max="item.inputAttr ? item.inputAttr.max : 100"
                >
                </el-input-number>

                <!-- 循环input类型为 radio的取值 -->
                <el-radio-group
                  v-if="item.inputAttr.type === 'radio'"
                  v-model="scope.row[item.schemefieldName]"
                >
                  // eslint-disable-next-line vue/no-v-for-template-key
                  <template v-for="(radioItem, ind) in item.inputAttr.options">
                    <el-radio :label="radioItem.val" :key="ind">
                      {{ radioItem.label }}
                    </el-radio>
                  </template>
                </el-radio-group>

                <el-input
                  v-if="item.inputAttr.type === 'text'"
                  v-model="scope.row[item.schemefieldName]"
                  size="mini"
                  :min="item.inputAttr.minLength || 0"
                  :max="item.inputAttr || 100"
                >
                </el-input>
              </template>
            </el-table-column>
          </slot>
        </template>
        <slot></slot>
      </el-table>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  props: {
    // 使用自定义表头
    customHeader: {
      type: Boolean,
      defalt: true,
    },
    // 页码
    pageNum: {
      type: Number,
      defalt: 1,
    },
    // 当前页数
    currentPage: {
      type: Number,
      defalt: 1,
    },
    //每页多少条数据
    pageSizeList: {
      type: Number,
      defalt: 50,
    },
    // 加载标识
    pictLoading: {
      type: Boolean,
      defalt: false,
    },
    // 每一列数据的 id
    id: {
      type: String,
      default: "id",
    },
    // 是否条纹状表格
    stripe: {
      type: Boolean,
      default: false,
    },
    // 是否提示无更多数据
    noDataTip: {
      type: Boolean,
      default: false,
    },
    // 表格数据
    pageObj: {
      type: Object,
      default: null,
    },
    // 可跳转链接是否使用a标签
    useHyperlinks: {
      type: Boolean,
      default: true,
    },
    // 表头
    tableAttr: {
      type: Array,
      default: null,
    },
    // 表格高度
    tableHeight: {
      type: [String, Number, undefined],
      default: undefined,
    },
    // 是否开启边框
    border: {
      type: Boolean,
      default: false,
    },
    // 是否设置min-height
    minHeight: {
      type: Boolean,
      default: false,
    },
    // 使用父组件传来的objectApi，父组件使用路由传递的值
    objectApi: {
      type: String,
      default: "",
    },
    // 是否显示锁定功能
    showLock: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openDelay: 500, // 表头悬浮提示延迟时间
      showSearchTable: false,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      fieldId: "",
      multiChecked: false,
      relevantFieldId: "",
      relevantObjId: "",
      relevantObjApi: "",
      relevantPrefix: "",
      relevantObjectApi: "",
      countryCode: this.$cookies.get("countryCode"),
      skeletonOptions: {
        active: true,
        row: 18,
        column: 5,
        width: "18vw",
        height: "20px",
        justifyContent: "space-around",
      },
      schemefieldLength: 0, // 评分字段最大分值
      errTip: this.$i18n.t("label.vlidaterule.message"),
      field: "", // 要修改的字段名称
      fieldApiName: "", // 要修改的字段ApiName
      changeAll: false, // 是否应用于全部选中数据
      editableCellOptions: [],
      inputType: "", // 要修改数据显示类型
      toChangeValue: null, //要修改的数据
      dialogVisible: false,
      editModeEnabled: false,
      topImg1: "#icon-icon_sort_top",
      topImg2: "#icon-downArrow",
      origin: -1, // 选中数据起点
      pin: false, // 是否按住shift键
      dataList: this.pageObj.dataList,
      memoryCheckList: [],
      pageSize: [10, 20, 50, 100],
      pageInfo: true,
      checkedList: [],
      columnTooltip: true,
      isSelectList: [],
      selected: [],
      selection: [],
      total: 0, // 数据总条数
      showApply2Others: false,
      nameLableList: [],
      allSelectedFieldList: [],
      editableCellAttr: null, //单元格编辑字段信息
      dataId: "",
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      precision: 0,
      min: 0,
      max: 0,
      objModifyAll: null, // 管理员权限
      objModify: null, // 对象编辑权限
      objDelete: null, // 对象删除权限
      token: this.$CCDK.CCToken.getToken(),
      echoSelected: [],
      index: 1,
      str: "",
      projectId: "",
      // 文件预览参数
      showPreview: false,
      showPreviewData: {},
      previewUrl: "",
      isSaveBtnLoading: false, //保存加载状态
    };
  },
  methods: {
    // 获取被选中列表
    getCheckedList() {
      // 如果开启分页记录 memorycheckList中的所有数据
      if (this.memory) {
        // 将当前页的数据保存 memorycheckList中 防止bug
        return this.memoryCheckList;
      } else {
        return this.checkedList;
      }
    },
    // 强制刷新
    changeTableAttr(tableAttr) {
      this.nameLableList = [];
      tableAttr.forEach((item) => {
        this.nameLableList.push(item.nameLabel);
      });
      this.$nextTick(() => {
        this.$refs.tablePanel && this.$refs.tablePanel.doLayout();
        this.$forceUpdate();
      });
    },
    // 锁定
    locked(item) {
      // 关闭锁定气泡
      this.$refs[`${item.schemefieldName}-popover`][0].doClose();
      this.$refs[`${item.schemefieldName}-popover`][1].doClose();
      this.$emit("locked", ...arguments);
    },
    // 表格滚动懒加载
    append() {
      this.$emit("append");
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    // 滚动事件
    loadMore() {
      let that = this;
      let dom = document.getElementsByClassName("el-table__body-wrapper")[
        document.getElementsByClassName("el-table__body-wrapper").length - 1
      ];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        // 解决表格滚动时左右对不齐问题
        if (scrollDistance <= 30) {
          that.$refs.tablePanel && that.$refs.tablePanel.doLayout();
        }

        if (scrollDistance <= 10) {
          //等于0证明已经到底，可以请求接口
          if (!this.noDataTip) {
            that.fnThrottle(that.append, 500, 1000)();
          }
        }
      });
    },
    // 表格滚动懒加载
    clearSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.tablePanel.toggleRowSelection(row);
        });
      } else {
        this.$refs.tablePanel.clearSelection();
      }
    },
    // 行点击事件，改变选中的值
    rowClick(row) {
      this.$emit("changeDate", row);
    },
    // toggleSelection() {

    //   if (rows) {
    //     rows.forEach((row) => {
    //       this.$refs.tablePanel.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.tablePanel.clearSelection();
    //   }
    // },
  },
  watch: {
    pageObj: {
      // 分页数据发生改变时候选中选项框
      handler: function (oldVal, newVal) {
        // 定时器确保表格已经刷新
        setTimeout(() => {
          // 数据更新
          this.dataList = this.pageObj.dataList;
          this.dataList &&
            this.dataList.forEach((item, index) => {
              item.index = index;
            });
          // 打印传递数据
          // Bus.$emit('printList',this.dataList)
          this.$nextTick(() => {
            //解决第一次触发emit无效问题
            this.$store.state.printList = this.dataList;
          });

          // 创建选中
          if (!newVal || !newVal.dataList) {
            return false;
          }

          // 记录上次选中数据
          // TODO:修改选中之前的数据状态
          newVal.dataList.forEach((item) => {
            if (item.checked !== undefined) {
              this.$refs.tablePanel.toggleRowSelection(item);
            }
          });
        }, 20);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content-main {
  position: relative;
  clear: both;
  height: 100%;
}

.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 100;
}

.action-btn {
  color: #006dcc !important;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

.table-head-tool {
  .el-input {
    position: absolute;
    top: -3px;
    left: -10px;
    margin-left: 10px;
    // width: calc(100% - 40px);
  }

  ::v-deep .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 25px;
    padding-left: 0;
  }

  .dragBox {
    width: 49%;
  }

  .item-box-left-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 250px;
    max-height: 250px;

    .searchBox {
      position: relative;
      padding: 0 10px;
      top: 5px;
      height: 25px;
      margin-bottom: 0;

      i {
        position: absolute;
        z-index: 100;
      }

      ::v-deep .el-input__inner {
        padding-left: 32px;
      }
    }
  }

  .itemBox {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 0;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
  }

  .itemBox-left {
    border: none;
    min-height: 200px;
    max-height: 222px;
    margin-top: 2px;
  }

  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    cursor: move;

    &:hover {
      background: #fafaf9;
    }
  }

  .btns {
    width: 95%;
    margin: 10px 15px 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
  }
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 0;
  width: calc(100% - 20px);
}

::v-deep .el-progress-bar__outer {
  margin-right: 10px;
}

.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}

::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .el-table-column--selection .cell {
  text-align: center;
}

::v-deep .el-table__fixed-body-wrapper {
  // top: 48px !important;
}

::v-deep .el-dialog__footer {
  clear: both;
}

// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}

.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}

// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}

// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid #dedcda;
}

::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}

::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

// 进度条样式
.el-progress {
  display: inline;
}

::v-deep .el-table td .cell {
  padding-right: 20px;
}

::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}

// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}

.admin_s {
  margin-top: -4px;
  cursor: pointer;
  width: 14px;
}

::v-deep .el-table .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  // padding-right: 20px;
}

::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;
}

::v-deep .el-table--border td {
  border-right: none;
}

.allow-click {
  color: #006dcc;
  text-decoration: solid;
}
::v-deep .is-checked {
  .el-checkbox__input {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
::v-deep .el-checkbox {
  .is-indeterminate {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
</style>
<style scoped>
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
